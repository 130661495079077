<script>
import PriceLayout from '#/v-shop-base-layout/vue-src/v-shop/product/comp_PriceLayout.vue'
export default {
	extends: PriceLayout,
}
</script>

<template>
	<div>
		<div class="d-flex align-center">
			<ProductPrice class="mr-2" :amount="price" :customClass="priceClass" :currency="currency" />
			<span v-if="unitMetric" class="mr-2 font-2 grey--text">/{{ unitMetric.split('/')[0] }}</span>
		</div>
		<div v-if="!hidePrevPrice && hasPrevPrice">
			<div class="d-flex align-baseline">
				<ProductPrevPrice :amount="prevPrice" :customClass="prevPriceClass" :currency="currency" />
				<ProductDiscount v-if="discountPct" :amount="discountPct" :customClass="discountClass" />
			</div>
		</div>
	</div>
</template>

